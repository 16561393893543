<template>
    <div class="discuss-container">
        <div class="discuss-content">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 3">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">班级管理</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>讨论列表</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>讨论列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="discuss-list">
                <el-table :data="discussList" border style="width: 100%; flex: 1; margin-top: 20px" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
                    <el-table-column prop="course_id" label="课程序号" width="180" ></el-table-column>
                    <el-table-column prop="course_name" label="课程名称"></el-table-column>
                    <el-table-column prop="discuss_num" label="讨论数" width="180" align="center"></el-table-column>
                    <el-table-column prop="teach_hours" label="授课时间" width="180" align="center"></el-table-column>
                    <el-table-column label="操作" width="180" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="clickViewDetail(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                               class="pages-center" background
                               :current-page.sync="discussPages.currentPageNum"
                               :page-size="discussPages.eachPageNum"
                               :total="discussPages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="discussCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "discussList",
        data() {
            return {
                //班级名称，课程id，上课记录id
                className: this.$route.query.className || '',
                classId: Number(this.$route.query.classId) || '',
                recordId: Number(this.$route.query.recordId) || '',
                //讨论列表数据
                discussList: [],
                //分页
                discussPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                role: Number(localStorage.getItem('role'))
            }
        },
        created() {
            this.getDiscussList();
        },
        methods: {
            //获取讨论列表
            getDiscussList() {
                let param = {
                    c_id: this.classId,
                    page: this.discussPages.currentPageNum,
                    limit: this.discussPages.eachPageNum,
                }
                this.$shttp.axiosGetBy(this.$api.showDiscussList, param, (res) => {
                    if (res.code === 200) {
                        this.discussList = res.data.data
                        this.discussPages.total = res.data.total
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分页
            discussCurrentChange(val) {
                this.discussPages.currentPageNum = val;
                this.getDiscussList();
            },
            //查看详情
            clickViewDetail(row) {
                this.$router.push({
                    path: '/teacherClass/discussDetail',
                    query: {
                        c_id: row.c_id,
                        course_record_id: row.course_record_id,
                        course_name: row.course_name,
                        className: this.className,
                    }
                })
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
        }
    }
</script>

<style scoped lang="scss">
    .discuss-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
        .discuss-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 1200px;
            margin: 0 auto;
        }
    }
    ::v-deep .el-breadcrumb {
        margin-bottom: 20px;
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .discuss-list {
        display: flex;
        flex: 1;
        height: 1%;
        flex-direction: column;
        background-color: #fff;
        .detail-title {
            cursor: pointer;
        }
    }
    .pages-center {
        margin-bottom: 20px;
    }
</style>